import { useState } from "react";
import classNames from "classnames";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import PhoneInput from "react-phone-number-input/input";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";
import style from "./style.module.scss";
import { trackEvent } from "../../utils/analytics";

export type ConfirmReturnToPoliVectorProps = {
  onBack: () => void;
  onConfirm: (phoneNumber?: string) => void;
};

export const ConfirmReturnToPoliVector = ({
  onBack,
  onConfirm,
}: ConfirmReturnToPoliVectorProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

  const isPhoneNumberValid = !phoneNumber || isValidPhoneNumber(phoneNumber);
  const submitMessage = phoneNumber
    ? "Send SMS & return to Pay by Bank (Classic POLi)"
    : "Return to Pay by Bank (Classic POLi)";

  return (
    <Container>
      <div className={style.content}>
        <h2>Use POLi Mobile for your next payment</h2>
        <p>Ready to streamline your payments with POLi Mobile?</p>
        <p>
          Enter your mobile number below to download the app - we&apos;ll send
          you the link right away.
        </p>
        <p>
          POLi Mobile is simpler and faster - make and manage your payments all
          in one place.
        </p>
      </div>
      <form
        className={style.form}
        onSubmit={(e) => {
          e.preventDefault();

          if (phoneNumber) {
            if (isPhoneNumberValid) {
              setPhoneNumberError(null);
              onConfirm(phoneNumber);
            } else {
              trackEvent("Entered invalid mobile number");
              setPhoneNumberError("Invalid mobile number");
            }
          } else {
            // No Phone Number
            onConfirm();
          }
        }}
      >
        <div className={classNames(style.content, style.inputContainer)}>
          <label htmlFor="phoneNumber">Mobile number</label>
          <PhoneInput
            id="phoneNumber"
            autoComplete="tel"
            defaultCountry="NZ"
            withCountryCallingCode
            value={phoneNumber}
            onChange={(value) => {
              setPhoneNumberError(null);
              setPhoneNumber(value);
            }}
          />
          <div className={style.error}>{phoneNumberError ?? "\xa0"}</div>
        </div>

        <div className={style.section}>
          <Button variant="primary" label={submitMessage} type="submit" />
          <Button
            variant="secondary"
            label="Back to POLi Mobile QR code"
            onClick={onBack}
            style={{ marginTop: 4 }}
          />
        </div>
      </form>
    </Container>
  );
};
