import { PaymentHandoffQueryParameters, PaymentId } from "@poli-app/models";
import { HandOff } from "../views/HandOff";
import { useContext, useState } from "react";
import { ConfirmReturnToPoliVector } from "../views/ReturnToPoli";
import { PaymentContext } from "../contexts/PaymentContext";
import { InProgress } from "../views/InProgress";
import { Terminal } from "../views/Terminal";
import { ErrorView } from "../views/ErrorView";
import { trackError, trackEvent } from "../utils/analytics";
import { Loading } from "../views/Loading";
import { getAuthToken } from "../utils/auth";

import { toast } from "react-toastify";

const hasIssue = (responseBody: unknown, issueCode: string) =>
  responseBody !== null &&
  typeof responseBody === "object" &&
  "issues" in responseBody &&
  Array.isArray(responseBody.issues) &&
  responseBody.issues.some((issue: any) => issue.code === issueCode);

/**
 * Checks that the payment hasn't been started via the mobile app and returns
 * it to a state which is safe to be completed via the POLi vector.
 */
const revertPayment = async (_payment: PaymentId, mobileReferral?: string) => {
  const url = `${import.meta.env.POLI_API_URL}/web/payment/revert`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ mobileReferral }),
  });

  // Check response status
  if (!response.ok) {
    let data: any;
    let message = "Sorry something went wrong. Please try again.";

    try {
      data = await response.json();
    } catch (error) {
      trackError("Unknown response", {
        url,
        status: response.status,
        requestId: response.headers.get("apigw-requestid"),
        _payment,
      });

      return {
        success: false,
        message,
      } as const;
    }

    // If the payment has already been attempted/started in the mobile app,
    // we can't safely return the user to the POLi vector.
    if (hasIssue(data, "payment_already_in_progress")) {
      message = "This payment has already been started in POLi Mobile";
    } else {
      trackError("Failed to revert Payment", {
        url,
        status: response.status,
        requestId: response.headers.get("apigw-requestid"),
        _payment,
        ...data,
      });
    }
    return { success: false, message } as const;
  }

  return { success: true } as const;
};

export const PaymentPage = () => {
  const { payment, enablePolling } = useContext(PaymentContext);
  const [confirmBackToPoli, setConfirmBackToPoli] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  // Keep track of the payment last_viewed_at timestamp: if this progresses from
  // it's original value then the code has been scanned since loading the page
  // and we should show a loading state.
  const [initialLastViewedAt, setInitialLastViewedAt] = useState(
    payment.last_viewed_at
  );

  // If the "redirect" flag is present, then we are on the user's primary device,
  // so on completion we should redirect back to the merchant. Otherwise, we are
  // on a secondary device, so should instead prompt the user to close the window.
  const shouldRedirect = Boolean(
    new URLSearchParams(window.location.search).get("redirect")
  );

  const paymentHasBeenScannedSincePageLoad =
    payment.last_viewed_at &&
    (initialLastViewedAt === null ||
      initialLastViewedAt < payment.last_viewed_at);

  // TODO: remove reliance on client's system time
  const paymentHasTimedOut = payment.timeout_at < new Date().toISOString();

  const qrValue = new URL(window.location.href);
  // Remove the "redirect" flag, as scanning the QR code implies starting a
  // decoupled flow.
  qrValue.searchParams.delete(
    "redirect" satisfies keyof PaymentHandoffQueryParameters
  );

  const paymentStatus = payment.status ?? null;

  const onRevertToClassicPoli = async (phoneNumber?: string) => {
    setIsCancelling(true);
    // Disable polling, otherwise we will show an error page briefly after the
    // payment is successfully reverted.
    enablePolling(false);
    try {
      const result = await revertPayment(payment._id, phoneNumber);
      if (result.success) {
        // We await here to make sure the event is sent before navigating away.
        await trackEvent("Returning to POLi", {
          _payment: payment._id,
          sentReferralSms: !!phoneNumber,
        });
        window.location.replace(payment.back_url);
        return;
      }
      trackEvent("Failed to revert payment", {
        _payment: payment._id,
        message: result.message,
      });
      toast.error(result.message);
    } catch (e: any) {
      trackError(e, { _payment: payment._id });
      toast.error("Sorry something went wrong. Please try again.");
    }
    // Didn't redirect (something went wrong)
    setConfirmBackToPoli(false);
    setIsCancelling(false);
    enablePolling(true);
  };

  if (isCancelling) {
    return <Loading message={"Returning to POLi pay by bank"} />;
  }

  if (confirmBackToPoli) {
    return (
      <ConfirmReturnToPoliVector
        onBack={() => setConfirmBackToPoli(false)}
        onConfirm={onRevertToClassicPoli}
      />
    );
  }

  if (paymentHasTimedOut) {
    return (
      <ErrorView
        title="Payment expired"
        message={[
          `Your payment to ${payment.merchant_name} has expired`,
          `Please return to ${payment.merchant_name} and try again`,
        ].join("\n")}
      />
    );
  }

  switch (paymentStatus) {
    case null:
    case "READY":
      if (paymentHasBeenScannedSincePageLoad) {
        return (
          <InProgress
            canRetry
            onRetry={() => {
              // Go back to the HandOff screen.
              setInitialLastViewedAt(payment.last_viewed_at);
            }}
          />
        );
      }

      return (
        <HandOff
          qrData={qrValue.toString()}
          onRequestReturnToPoli={async () => {
            trackEvent("Requested return to POLi");
            await onRevertToClassicPoli();
          }}
          onRequestAppReferral={() => {
            trackEvent("Requested app referral");
            setConfirmBackToPoli(true);
          }}
        />
      );

    case "PROCESSING":
      return <InProgress />;

    case "CANCELLED":
    case "ERROR":
    case "COMPLETE":
      return (
        <Terminal
          terminalStatus={paymentStatus}
          shouldRedirect={shouldRedirect}
          onRedirect={() => {
            let url: string;
            switch (paymentStatus) {
              case "COMPLETE":
                url = payment.success_url;
                break;

              case "CANCELLED":
                url = payment.cancelled_url;
                break;

              default:
                url = payment.failure_url;
                break;
            }
            window.location.replace(url);
          }}
        />
      );

    default: {
      const error = new Error(`Unknown payment status: ${paymentStatus}`);
      trackError(error, { _payment: payment._id, paymentStatus });
      throw error;
    }
  }
};
