import Loader from "react-loading";
import style from "./style.module.scss";

export const Loading = () => (
  <Loader
    className={style.loader}
    type="spinningBubbles"
    color="var(--color-primary)"
  />
);
