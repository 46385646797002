import style from "./style.module.scss";
import numeral from "numeral";
import { ApiPayment } from "@poli-app/models";
import { MerchantLogo } from "../MerchantLogo";

/**
 * Primary UI component for user interaction
 */
export const PaymentInfo: React.FC<{ payment: ApiPayment; title?: string }> = ({
  payment,
  title = "Paying",
}) => {
  return (
    <>
      <div className={style.container}>
        <div className={style.header}>
          <h1>{title}</h1>
        </div>
        <div className={style.row}>
          <MerchantLogo
            title={payment.merchant_name}
            alt={payment.merchant_name}
            uri={payment.merchant_logo}
            scale={0.8}
            style={{ marginRight: 15 }}
          />
          <div>
            <p>
              <b>{payment.merchant_name}</b>
            </p>
            <p>
              <b>Amount:</b> {numeral(payment.amount).format("$0,000.00")}
            </p>
            <p>
              <b>Reference:</b> {payment.poli_merchant_reference}
            </p>
            <p>
              <b>POLi ID:</b> {payment.poli_id}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
