import { Container } from "../../components/Container";
import { QrCode } from "../../components/QrCode";
import { Button } from "../../components/Button";
import { PaymentInfo } from "../../components/PaymentInfo";
import style from "./style.module.scss";
import { useContext, useState } from "react";

import { trackEvent } from "../../utils/analytics";

import { PaymentContext } from "../../contexts/PaymentContext";
import { PlatformContext } from "../../contexts/PlatformContext";
import { ShortCode } from "../../components/ShortCode";

export type HandOffProps = {
  qrData: string;
  onRequestAppReferral: () => void;
  onRequestReturnToPoli: () => void;
};

export const HandOff = ({
  qrData,
  // onRequestAppReferral,
  onRequestReturnToPoli,
}: HandOffProps) => {
  const [showQrOnMobile, setShowQrOnMobile] = useState(false);
  const [showShortCode, setShowShortCode] = useState(false);

  const { payment } = useContext(PaymentContext);
  const { isMobile } = useContext(PlatformContext);

  return (
    <Container>
      <PaymentInfo payment={payment} />

      <div className={style.container}>
        {isMobile && !showQrOnMobile ? (
          <>
            <div className={style.content}>
              <h2>
                You do not have the POLi Mobile app installed on this device
              </h2>
              <p style={{ textAlign: "center" }}>
                Have the app on another device? <br />
                <button
                  className="button-link"
                  title="Show QR code"
                  onClick={() => {
                    trackEvent("Show QR Code Button Clicked");
                    setShowQrOnMobile(true);
                  }}
                >
                  Show QR code
                </button>
              </p>
            </div>
            <div className={style.cta}>
              {/* <button
                className="button-link"
                style={{ textDecoration: "underline" }}
                onClick={onRequestAppReferral}
              >
                Get the POLi Mobile app
              </button> */}
              <Button
                variant="primary"
                label="Return to Pay by Bank (Classic POLi)"
                onClick={onRequestReturnToPoli}
              />
            </div>
          </>
        ) : (
          <>
            <div className={style.content}>
              {showShortCode ? (
                <>
                  <h2>
                    Open the POLi Mobile app and enter this manual code to
                    complete your payment
                  </h2>
                  <div className={style.code}>
                    <ShortCode shortCode={payment.short_code} />
                    <p style={{ textAlign: "center" }}>
                      <button
                        title="Show QR code"
                        className="button-link"
                        onClick={() => setShowShortCode(false)}
                      >
                        Show QR code
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <h2>
                    Scan this QR code with the POLi Mobile app to complete your
                    payment
                  </h2>
                  <div className={style.code}>
                    <QrCode value={qrData} scaleFactor={2} />
                    <p style={{ textAlign: "center" }}>
                      Having trouble scanning the QR code?
                      <br />
                      <button
                        title="Show manual payment code"
                        className="button-link"
                        onClick={() => setShowShortCode(true)}
                      >
                        Show manual code
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className={style.cta}>
              {/* <button
                className="button-link"
                style={{ textDecoration: "underline" }}
                onClick={onRequestAppReferral}
              >
                Get the POLi Mobile app
              </button> */}
              <Button
                variant="primary"
                label="Return to Pay by Bank (Classic POLi)"
                onClick={onRequestReturnToPoli}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};
