import { createContext } from "react";
import { platform } from "../utils/platform";

export type PlatformContextData = {
  isMobile: boolean;
};

export const PlatformContext = createContext<PlatformContextData>({
  isMobile: platform.getPlatformType() === "mobile",
});
