import { trackError } from "../../utils/analytics";
import styles from "./style.module.scss";

export type ShortCodeProps = { shortCode: string };

export const ShortCode: React.FC<ShortCodeProps> = ({ shortCode }) => {
  const parts = shortCode.match(/.{3}/g);

  if (!parts) {
    trackError(new Error(`Unable to display code ${shortCode}`));
    return "Unable to display code";
  }

  const [part1, part2, part3] = parts;

  return (
    <div className={styles.shortCode}>
      <span>{part1}</span>
      <span>-</span>
      <span>{part2}</span>
      <span>-</span>
      <span>{part3}</span>
    </div>
  );
};
