import { Loading as LoadingComponent } from "../../components/Loading";
import { Container } from "../../components/Container";
import style from "./style.module.scss";

export type LoadingProps = {
  message?: string;
};

export const Loading = (props: LoadingProps) => {
  return (
    <Container>
      <div className={style.content}>
        <LoadingComponent />
        {props.message ? (
          <p className={style.message}>{props.message}</p>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};
