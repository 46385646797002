// Do not import above this line
import "./utils/analytics";
import "./styles/globals.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PaymentPage } from "./pages/Payment";
import { PaymentContextProvider } from "./contexts/PaymentContext";
import { ErrorView } from "./views/ErrorView";
import { DownloadPage } from "./pages/Download";

import { platform } from "./utils/platform";
import { trackEvent } from "./utils/analytics";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// On some Android browsers (e.g. Firefox, Samsung Internet), app links aren't
// enabled by default. We can however simply try to navigate using the poli://
// scheme instead. If the app isn't installed, this is a no-op. If the app is
// installed, it will either be opened directly or show a prompt to the user
// first depending on browser implementation/preferences. On Chrome this appears
// to always be a no-op.
if (window.location.pathname === "/txn" && platform.getOSName() === "Android") {
  try {
    const { pathname, search } = window.location;
    const appSchemeLink = `poli://${pathname}${search}`;
    trackEvent("Attempting app scheme link", { appSchemeLink });
    window.location.href = appSchemeLink;
  } catch {
    // pass
  }
}

const router = createBrowserRouter([
  // react-router v6 has added some stupid intrusive built-in error boundary
  // feature that can't be turned off. This requires an `errorElement` to be
  // specified somewhere in the route tree or else a butt-ugly default will be
  // shown when an exception is thrown. To avoid needing to specify this on
  // __every__ route, we create a nested route structure and apply an
  // `errorElement` at the top level.
  // https://github.com/remix-run/react-router/discussions/10166
  {
    path: "/",
    children: [
      {
        path: "/txn",
        element: (
          <PaymentContextProvider>
            <PaymentPage />
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </PaymentContextProvider>
        ),
      },
      {
        path: "/download",
        element: <DownloadPage />,
      },
    ],
    errorElement: (
      <ErrorView
        title="Something went wrong"
        message="Sorry, we've encountered an unexpected error."
      />
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
