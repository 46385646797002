import { CSSProperties } from "react";
import { trackEvent } from "../../utils/analytics";

export type MerchantLogoProps = {
  uri: string;
  alt?: string;
  title?: string;
  style?: CSSProperties;
  scale?: number;
};

export const MerchantLogo = (props: MerchantLogoProps) => {
  const scale = props.scale ?? 0.5;

  return (
    <img
      src={props.uri}
      title={props.title}
      alt={props.alt}
      style={{
        display: "flex",
        objectFit: "contain",
        width: 150 * scale,
        height: 68 * scale,
        ...props.style,
      }}
      onError={(error: any) => {
        trackEvent("Failed to load image", {
          uri: props.uri,
          error: error.message,
        });
      }}
    />
  );
};
