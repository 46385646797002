import { Container } from "../../components/Container";
import style from "./style.module.scss";
import { useContext, useEffect } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { Button } from "../../components/Button";
import { PaymentInfo } from "../../components/PaymentInfo";
import { trackEvent } from "../../utils/analytics";

export type ErrorViewProps = {
  title: string;
  message: string;
};

export const ErrorView = ({ title, message }: ErrorViewProps) => {
  const { payment } = useContext(PaymentContext);

  useEffect(() => {
    trackEvent("Shown Error", {
      title,
      message,
    });
  }, [title, message]);

  return (
    <Container>
      {payment ? (
        <PaymentInfo payment={payment} title={title} />
      ) : (
        <div className={style.header}>
          <h1>{title}</h1>
        </div>
      )}
      <div className={style.content}>
        <p style={{ whiteSpace: "pre-wrap" }}>{message}</p>
      </div>
      {payment ? (
        <div className={style.cta}>
          <Button
            variant="primary"
            label={`Return to ${payment.merchant_name}`}
            onClick={() => {
              window.location.replace(payment.failure_url);
            }}
          />
        </div>
      ) : null}
    </Container>
  );
};
