import classNames from "classnames";
import styles from "./style.module.scss";
import { ButtonHTMLAttributes } from "react";

export type ButtonProps = {
  /**
   * Button Style, changes fill and coloring
   */
  variant: "primary" | "secondary";
  /**
   * Button Label
   */
  label: string;
  /**
   * Click handler
   */
  onClick?: () => void;
  /**
   * Optional class name
   */
  className?: string;
  /**
   * Optional style
   */
  style?: React.CSSProperties;
  /**
   * Button type
   */
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variant,
  label,
  onClick,
  className,
  style,
  type,
}: ButtonProps) => {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
      })}
      style={style}
      type={type}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick!();
            }
          : undefined
      }
    >
      {label}
    </button>
  );
};
