import { Fragment } from "react";
import qr from "qrcode";
import style from "./style.module.scss";
import { PoliOPath } from "./poliOPath";

export type QrCodeProps = {
  /**
   * Determines the scale of the QrCode, the default scale is 1.5x of 128x128px.
   *
   * @default 1.5
   */
  scaleFactor?: number;
  /**
   * The value to encode in the QrCode.
   */
  value: string;
};

/** Rounds an number to an even number */
function roundToEven(n: number) {
  return Math.round(n / 2) * 2;
}

/**
 * A QR Code styled with POLi branding.
 *
 * This file uses the term "unit" to refer to one square in a QR code.
 * We essentially work out the dimensions in "unit"s, then convert that to
 * pixels at render time.
 */
export const QrCode = ({ value, scaleFactor = 1.5 }: QrCodeProps) => {
  /** The width/height of the code in pixels */
  const qrSize = 128 * scaleFactor;
  /** Padding around the code in pixels */
  const qrPadding = 6 * scaleFactor;

  // Generate the QR data
  const qrMatrix = qr.create(value, {
    errorCorrectionLevel: "H",
  }).modules;
  /** How many units make up the side of this QR code */
  const qrUnitSize = qrMatrix.size;
  /** How tall/wide a "unit" is */
  const unitDimensions = qrSize / qrUnitSize;

  /** The number of QR "units" to fill with the logo */
  const logoUnitSize = roundToEven(qrUnitSize / 4);
  /** The column/row at which to start the logo */
  const logoStart = qrUnitSize / 2 - logoUnitSize / 2;
  /** The number of QR "units" to pad around the logo */
  const logoPaddingUnitSize = 3;
  /** The column/row at which to start the white padding for the logo */
  const logoPaddingStart = Math.floor(
    qrUnitSize / 2 - logoUnitSize / 2 - logoPaddingUnitSize
  );
  /** The column/row at which to stop the white padding for the logo */
  const logoPaddingStop = Math.floor(
    qrUnitSize / 2 + logoUnitSize / 2 + logoPaddingUnitSize
  );

  return (
    <div
      className={style.container}
      style={{
        padding: `${qrPadding}px`,
      }}
    >
      <svg style={{ width: qrSize, height: qrSize }}>
        {/* The QR code */}
        {Array(qrUnitSize)
          .fill(0)
          .map((_, rowIndex) => {
            return Array(qrUnitSize)
              .fill(0)
              .map((_, columnIndex) => {
                const isInsidePadding =
                  rowIndex > logoPaddingStart &&
                  rowIndex < logoPaddingStop &&
                  columnIndex > logoPaddingStart &&
                  columnIndex < logoPaddingStop;
                const shouldDisplay =
                  qrMatrix.get(rowIndex, columnIndex) && !isInsidePadding;
                if (shouldDisplay) {
                  return (
                    <rect
                      key={`${rowIndex}_${columnIndex}`}
                      y={rowIndex * unitDimensions}
                      x={columnIndex * unitDimensions}
                      width={unitDimensions}
                      height={unitDimensions}
                      style={{ fill: "#2f459c" }}
                    />
                  );
                } else {
                  return (
                    <Fragment key={`${rowIndex}_${columnIndex}`}></Fragment>
                  );
                }
              });
          })}
        {/* Transform the logo to put it at the right size and in the center */}
        <g
          transform={`
          translate(${logoStart * unitDimensions}, ${
            logoStart * unitDimensions
          })
          scale(${logoUnitSize * unitDimensions})`}
        >
          {/* The POLi logo */}
          <PoliOPath />
        </g>
      </svg>
    </div>
  );
};
