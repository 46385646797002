import Loading from "react-loading";
import { Container } from "../../components/Container";
import { PaymentInfo } from "../../components/PaymentInfo";
import style from "./style.module.scss";
import { useContext, useEffect } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { Button } from "../../components/Button";
import { trackEvent } from "../../utils/analytics";

export type InProgressProps = {
  /** Can the user go back to the QR code? (default: false) */
  canRetry?: boolean;
  /** Callback when the user wants to go back to the QR code */
  onRetry?: () => void;
};

export const InProgress = ({ onRetry, canRetry }: InProgressProps) => {
  const { payment } = useContext(PaymentContext);

  useEffect(() => {
    trackEvent("Payment pending in app");
  }, []);

  return (
    <Container>
      <PaymentInfo payment={payment} />
      <div className={style.content}>
        <h2>Proceed with payment in the POLi Mobile app</h2>
        <p>Please keep this page open while completing your payment.</p>
        <Loading
          className={style.loader}
          type="spinningBubbles"
          color="var(--color-primary)"
        />
        <p className={style.redirectInfo}>
          After you finish paying in the app, we will redirect you back to{" "}
          <b>{payment.merchant_name}</b>
        </p>
      </div>
      {canRetry ? (
        <div className={style.section}>
          <Button variant="secondary" label="Show QR code" onClick={onRetry} />
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};
