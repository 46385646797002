import Loading from "react-loading";
import { Container } from "../../components/Container";
import { PaymentInfo } from "../../components/PaymentInfo";
import style from "./style.module.scss";
import { useContext, useEffect } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { trackEvent } from "../../utils/analytics";
import { PaymentStatus } from "@poli-app/models";

export type TerminalProps = {
  terminalStatus: Extract<PaymentStatus, "COMPLETE" | "CANCELLED" | "ERROR">;
  shouldRedirect: boolean;
  onRedirect: () => void;
};

export const Terminal = ({
  onRedirect,
  shouldRedirect,
  terminalStatus,
}: TerminalProps) => {
  const { payment } = useContext(PaymentContext);

  useEffect(() => {
    trackEvent("Shown terminal page", { shouldRedirect });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldRedirect) {
      // Redirect after 3 seconds.
      const timeout = setTimeout(() => {
        trackEvent("Redirecting back to merchant");
        onRedirect();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [onRedirect, shouldRedirect]);

  const title = `Payment ${
    {
      COMPLETE: "completed",
      CANCELLED: "cancelled",
      ERROR: "failed",
    }[terminalStatus]
  }`;

  return (
    <Container>
      {shouldRedirect ? (
        <>
          <PaymentInfo payment={payment} title={""} />
          <div className={style.content}>
            <h2>Redirecting back to {payment.merchant_name}</h2>
            <Loading
              className={style.loader}
              type="spinningBubbles"
              color="var(--color-primary)"
            />
            <p className={style.redirectInfo}>
              If you have not been redirected,{" "}
              <button
                title={`Return to ${payment.merchant_name}`}
                className="button-link"
                onClick={onRedirect}
              >
                click here
              </button>
            </p>
          </div>
        </>
      ) : (
        <>
          <PaymentInfo payment={payment} title={title} />
          <div className={style.content}>
            <h2>You may now close this window</h2>
            <p>
              This payment{" "}
              {
                {
                  COMPLETE: "has been completed on a different device",
                  CANCELLED: "has been cancelled on a different device",
                  ERROR: "has failed",
                }[terminalStatus]
              }
              .
              <br />
              You may now close this window.
            </p>
          </div>
        </>
      )}
    </Container>
  );
};
