import React, { useEffect } from "react";
import styles from "./download.module.scss";
import { platform } from "../utils/platform";
import { trackEvent } from "../utils/analytics";

const osName = platform.getOSName().toLowerCase();

export const DownloadPage: React.FC = () => {
  // Firefox cancels all in-progress requests when navigation is attempted using
  // window.location.href = "..."
  // When on Android, we immediately attempt to navigate to the app store link
  // after first render. In combination with a Firefox browser, this has the
  // effect of cancelling the in-flight browser requests to load the app store
  // download button images. If the user cancels the prompt to open the app
  // store, they will see our page rendered but with missing download links!
  // We work-around this by forcing the app store images to re-load after this
  // navigation attempt. This is done by changing a query parameter on the image
  // src URL.
  const [renderVersion, setRenderVersion] = React.useState(0);
  const imgSrc = (src: string) => `${src}?v=${renderVersion}`;

  useEffect(() => {
    if (osName === "android") {
      window.location.href = "market://details?id=com.paywithpoli.app";
      setRenderVersion(1);
    }

    if (osName === "ios") {
      window.location.href = "https://apps.apple.com/app/id6475696737";
    }

    trackEvent("Download page view", { osName });

    // Set page title
    const originalPageTitle = document.title;
    document.title = "POLi Mobile | Download";

    return () => {
      document.title = originalPageTitle;
    };
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.hero}>
        <picture className={styles.image}>
          <source
            srcSet={imgSrc("/download-hero-image.webp")}
            type="image/webp"
          />
          <img src={imgSrc("/download-hero-image.png")} alt="POLi Mobile" />
        </picture>

        <div className={styles.content}>
          <h1 className={styles.title}>Download the POLi Mobile app</h1>

          <div className={styles.subtitle}>
            <p>Download the POLi Mobile app today.</p>
            <p>
              Seamlessly make and manage all your payments online using the POLi
              Mobile app.
            </p>
          </div>

          <div className={styles.badges}>
            <a
              className={styles.badge}
              href="https://apps.apple.com/nz/app/poli-mobile/id6475696737"
            >
              <img
                src={imgSrc("/apple-app-store-badge.png")}
                alt="Download POLi Mobile from the Apple App Store"
              />
            </a>
            <a
              className={styles.badge}
              href="https://play.google.com/store/apps/details?id=com.paywithpoli.app"
            >
              <img
                className={styles.badge}
                src={imgSrc("/google-play-badge.png")}
                alt="Download POLi Mobile from the Google Play Store"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
