import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/browser";

let analyticsEnabled = false;

/**
 * Adds metadata to all following events
 */
export function addMetadata(meta: Record<string, any>) {
  if (!analyticsEnabled) {
    console.log("addMetadata", { meta });
    return;
  }
  mixpanel.register(meta, { persistent: false });
  Sentry.setExtras(meta);
}

/**
 * Creates an analytics event, optionally with metadata
 */
export async function trackEvent(name: string, meta?: Record<string, any>) {
  if (!analyticsEnabled) {
    console.log("trackEvent", { name, meta });
    return;
  }
  Sentry.addBreadcrumb({
    message: name,
    data: meta,
    level: "info",
  });
  await new Promise((resolve) => {
    mixpanel.track(name, meta, resolve);
  });
}

export function trackError(error: Error | string, meta?: Record<string, any>) {
  if (!analyticsEnabled) {
    console.log("trackError", { error, meta });
    return;
  }

  if (typeof error === "string") {
    error = new Error(error);
  }

  Sentry.captureException(error, {
    extra: meta,
  });
  mixpanel.track("Exception", {
    name: error?.constructor?.name,
    message: error?.message,
  });
}

// Enable analytics outside storybook and where we have the config.
if (
  !import.meta.env.STORYBOOK &&
  !!import.meta.env.POLI_SENTRY_DSN &&
  !!import.meta.env.POLI_MIXPANEL_TOKEN
) {
  const environment = import.meta.env.MODE;
  const release = import.meta.env.POLI_SENTRY_RELEASE; // This is replaced at build time

  Sentry.init({
    dsn: import.meta.env.POLI_SENTRY_DSN,
    tunnel: import.meta.env.POLI_SENTRY_TUNNEL,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment,
    release,
  });

  mixpanel.init(import.meta.env.POLI_MIXPANEL_TOKEN, {
    api_host: import.meta.env.POLI_MIXPANEL_HOST,
    ignore_dnt: true,
  });
  mixpanel.register({
    environment,
    release,
  });

  analyticsEnabled = true;
} else {
  console.log("No Analytics enabled");
}
