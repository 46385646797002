import style from "./style.module.scss";
import banner from "/poli.svg";

export type ContainerProps = {
  children: React.ReactNode;
};

/**
 * The UI Container for a Page.
 */
export const Container = ({ children }: ContainerProps) => {
  return (
    <div className={style.container}>
      <div className={style.banner}>
        <img
          src={banner}
          alt="POLi Payments"
          title="POLi Payments"
          style={{ marginLeft: -5 }}
        />
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
};
