import { createContext, useState, useEffect } from "react";
import type { ApiPayment } from "@poli-app/models";

import { getAuthToken } from "../utils/auth";
import { trackEvent, trackError, addMetadata } from "../utils/analytics";
import { ErrorView } from "../views/ErrorView";
import { Loading } from "../views/Loading";

const POLL_STATUS_INTERVAL = 1000;

const PAYMENT_UNEXPECTED_ERROR =
  "We were unable to retrieve your payment information, this may happen if the payment has timed out, or the system may be temporarily unavailable.";

export type PaymentContextData = {
  payment: ApiPayment;
  enablePolling: (enabled: boolean) => void;
};

export const PaymentContext = createContext<PaymentContextData>({
  payment: null as any,
  enablePolling: () => {
    throw new Error("Cannot alter polling before context is initiated");
  },
});

export const PaymentContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [payment, setPayment] = useState<ApiPayment | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // A boolean that children can set to disable/enable polling
  const [polling, setPolling] = useState(true);

  const token = getAuthToken();

  const fetchPayment = async (
    token: string,
    signal?: AbortSignal
  ): Promise<ApiPayment | null> => {
    try {
      const response = await fetch(
        `${import.meta.env.POLI_API_URL}/web/payment`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        // TODO: Error responses from API should be more descriptive
        if (response.status === 404) {
          setPayment(undefined);
          setErrorMessage(PAYMENT_UNEXPECTED_ERROR);
          return null;
        }
        trackEvent("Failed to fetch Payment", {
          status: response.status,
        });
        setPayment(undefined);
        setErrorMessage(PAYMENT_UNEXPECTED_ERROR);
        return null;
      }

      const payment = await response.json();
      setPayment(payment);
      setErrorMessage(undefined);

      return payment;
    } catch (e: any) {
      if (!signal?.aborted) {
        trackError(e);
        setErrorMessage(PAYMENT_UNEXPECTED_ERROR);
      }
      return null;
    }
  };

  useEffect(() => {
    if (!polling) {
      return;
    }

    if (!token) {
      trackEvent("Missing token");
      setErrorMessage(PAYMENT_UNEXPECTED_ERROR);
      if (import.meta.env.DEV) {
        setErrorMessage("Missing query parameters");
      }
      return;
    }

    const aborter = new AbortController();
    let timeout: NodeJS.Timeout | undefined;

    // Keep recursively polling the payment until it reaches a terminal state.
    const pollPaymentState = async () => {
      // Get the current status of the payment
      const payment = await fetchPayment(token, aborter.signal);

      const status = payment?.status ?? null;
      const isNonTerminalStatus =
        status === null || status === "READY" || status === "PROCESSING";
      const isTimedOut =
        payment && payment.timeout_at < new Date().toISOString();

      // If the payment hasn't been scanned, or is not in a final state,
      // continue polling.
      if (isNonTerminalStatus && !isTimedOut) {
        timeout = setTimeout(pollPaymentState, POLL_STATUS_INTERVAL);
      }
    };

    fetchPayment(token, aborter.signal)
      .then((payment) => {
        addMetadata({
          _payment: payment?._id,
          merchantReference: payment?.poli_merchant_reference,
          merchant: payment?.merchant_name,
        });
      })
      .then(() => new Promise((res) => setTimeout(res, POLL_STATUS_INTERVAL)))
      .then(pollPaymentState);

    return () => {
      aborter.abort();
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [polling, token]);

  if (errorMessage) {
    return <ErrorView title="Something went wrong" message={errorMessage} />;
  }

  if (!payment) {
    return <Loading />;
  }

  return (
    <PaymentContext.Provider
      value={{
        payment,
        enablePolling: setPolling,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
